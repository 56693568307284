// @use "../../config/variables.scss" as vars;

.loginScreen{
  width: 100%;
  height: 92vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginScreen-Box3{
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  img{
    width: 100%;
    height: 100px;
    object-fit: contain;
  }
}

.loginScreen-txt1{
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
  // font-family: vars.$Bold;
  // color: vars.$primary;
}