// @use "../../config/variables.scss" as vars;

.Dashboard-cards{
    background-color: #cbe5e1a3;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.Dashboard-cardBox1{
    background-color: rgba(255, 201, 65, 0.294);
    height: 50px;
    width: 50px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 30px;
    // color: vars.$primary;
}

.Dashboard-cardtxt1{
    font-size: 14px;
    color: rgb(90, 90, 90);
}

.Dashboard-cardtxt2{
    font-size: 25px;
    // color: vars.$primary;
    // font-family: vars.$Bold;
}

.Dashboard-ViewChart{
    height: 50vh;
}

.dashboard-Txt1{
    // color: vars.$primary;
    // font-family: vars.$Bold;
    font-size: 20px;
    margin-bottom: 10px;
}

.Dashboard-booking{
    border: 1px solid rgb(233, 233, 233);
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    padding-bottom: 0px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
}

.Dashboard-graph{
    border: 1px solid rgb(233, 233, 233);
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    background-color: #fff;
    height: 400px;
}