// @use "../config/variables.scss" as vars;

.LayoutHeader {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  height: 9vh;
}
.LayoutHeader-box {
  display: flex;
  flex-direction: row;
  align-items: center;
//   font-family: vars.$Bold;
//   color: vars.$primary;
  font-size: 20px;
}

.sideBar-Box {
  height: 100vh;
  overflow: scroll;
}

.sideBar-logo {
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 20px;
  img {
    width: 100%;
    height: 80px;
    object-fit: contain;
  }
}

.sideBar-txt1 {
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-bottom: 1px solid #0f415f;
//   font-family: vars.$Regular;
  font-size: 12px;
  color: #ffffff64;
  margin-left: 15px;
  margin-right: 15px;
}

.sideBar-item {
  display: flex;
  flex-direction: row;
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 10px;
  color: #fff;
  cursor: pointer;
//   font-family: vars.$Medium;
  &:hover {
    background-color: #fff0c932;
    border-radius: 5px;
    // color: vars.$primary1;
  }
  &.active {
    background-color: #fff0c932;
    border-radius: 5px;
    // color: vars.$primary1;
  }
}

.Profile-popover {
  min-width: 150px;
}

.Popover-txt1 {
  font-size: 18px;
//   font-family: vars.$SemiBold;
  padding-top: 10px;
}

.Popover-txt2 {
  font-size: 13px;
//   font-family: vars.$SemiBold;
}

.Popover-item1 {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(225, 225, 225);
  padding-bottom: 5px;
}

.Popover-item2 {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px 0;
  &:hover {
    background-color: rgb(224, 224, 224);
  }
}

.Popover-item3 {
//   background-color: vars.$primary;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: #fff;
  font-size: 14px;
//   font-family: vars.$SemiBold;
  margin-top: 15px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: "##01856e";
  }
}
.LayoutHeader-timer {
  margin-right: 10px;
//   font-family: vars.$SemiBold;
  font-size: 14px;
  border: 1px solid rgb(212, 212, 212);
  padding: 4px 12px;
  border-radius: 6px;
  min-width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading-txt2 {
//   font-family: vars.$Medium;
  font-size: 17px;
}
.QuickMenu-item {
//   font-family: vars.$Regular;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 100px;
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: left;
  margin-bottom: 10px;

  color: black;
  cursor: pointer;
  &:hover {
    // color: vars.$primary;
  }
}
